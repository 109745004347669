// import external dependencies
import 'jquery';
import Splide from '@splidejs/splide';

// Import everything from autoload


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';


/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// import then needed Font Awesome functionality
import { library, dom } from '@fortawesome/fontawesome-svg-core';
// import the Facebook and Twitter icons
import { faFacebookF, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faAngleRight, faAngleUp, faMagnifyingGlass, faStar, faPlay } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot, faMobile, faEnvelope, faPhone, faUser, faCartShopping, faPenToSquare } from '@fortawesome/pro-light-svg-icons';

// add the imported icons to the library
library.add(faFacebookF, faTwitter, faInstagram, faLocationDot, faUser, faStar, faPlay, faLinkedin, faPenToSquare, faCartShopping, faMagnifyingGlass, faMobile, faPhone, faEnvelope, faAngleRight, faAngleUp);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();

// Load Events
jQuery(document).ready(() => routes.loadEvents());




document.addEventListener('DOMContentLoaded', function () {

  var $dropdowns = getAll('.navbar-item.has-dropdown:not(.is-hoverable)');

  if ($dropdowns.length > 0) {
    $dropdowns.forEach(function ($el) {
      $el.addEventListener('click', function (e) {
        e.stopPropagation();
        $el.classList.toggle('is-active');
      });
    });

    document.addEventListener('click', function (event) {
      event.closeDropdowns();
    });
  }

  function closeDropdowns() {
    $dropdowns.forEach(function ($el) {
      $el.classList.remove('is-active');
    });
  }

  // Close dropdowns if ESC pressed
  document.addEventListener('keydown', function (event) {
    var e = event || window.event;
    if (e.keyCode === 27) {
      closeDropdowns();
    }
  });

  // Toggles

  var $burgers = getAll('.burger');

  if ($burgers.length > 0) {
    $burgers.forEach(function ($el) {
      $el.addEventListener('click', function () {
        var target = $el.dataset.target;
        var $target = document.getElementById(target);
        $el.classList.toggle('is-active');
        $target.classList.toggle('is-active');
      });
    });
  }

  // Functions

  function getAll(selector) {
    return Array.prototype.slice.call(document.querySelectorAll(selector), 0);
  }
});

document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('.main__slide') !== null) {
    new Splide('.main__slide',
      {
        type: 'loop',
        pagination: false,
        interval: 6000,
        speed: 500,
        autoplay: true,
        breakpoints: {
          640: {
            perPage: 1,
          },
        },
      }).mount();
  }

  if (document.querySelector('.quotes') !== null) {
    new Splide('.quotes',
      {
        type: 'loop',
        pagination: true,
        perPage: 1,
        arrows: false,
        interval: 6000,
        speed: 500,
        autoplay: true,
        perMove: 1,
        breakpoints: {
          640: {
            perPage: 1,
          },
        },
      }).mount();
  }
});


$('.wc-block-grid__product').mouseover(function () {
  $(this).children('.wc-block-grid__product-add-to-cart').toggleClass('show-addtocart');
});

$('.wc-block-grid__product').mouseout(function () {
  $(this).children('.wc-block-grid__product-add-to-cart').toggleClass('show-addtocart');
});


const accordionItems = document.querySelectorAll('.wbx__accordion__item');

accordionItems.forEach(item => {
  const title = item.querySelector('.wbx__accordion__item__title');
  const content = item.querySelector('.wbx__accordion__item__content');

  title.addEventListener('click', () => {
    const isActive = item.classList.contains('active');

    // Close all active items first
    accordionItems.forEach(activeItem => {
      const activeContent = activeItem.querySelector('.wbx__accordion__item__content');
      if (activeContent.style.maxHeight) {
        activeItem.classList.remove('active');
        activeContent.style.maxHeight = null;
      }
    });

    if (!isActive) {
      item.classList.add('active');
      content.style.maxHeight = content.scrollHeight + 'px';
    }
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const searchIcon = document.getElementById('search-icon');
  const searchContainer = document.querySelector('.top__header__search');
  const searchForm = searchContainer.querySelector('.search-form');
  const searchField = searchContainer.querySelector('.search-field');

  if (searchField.value !== '') {
    searchForm.classList.add('active');
  }

  searchIcon.addEventListener('click', () => {
    if (searchField.value !== '') {
      searchForm.submit();
    } else {
      searchForm.classList.toggle('active');
      if (searchForm.classList.contains('active')) {
        searchField.focus();
      }
    }
  });

  searchForm.addEventListener('submit', (e) => {
    if (searchField.value === '') {
      e.preventDefault();
      searchField.focus();
    }
  });
});

export function lockBodyScroll() {
  const { documentElement, body } = document;
  if (!documentElement.classList.contains('no-scroll')) {
    const scrollY = window.scrollY || window.pageYOffset;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}px`;
    documentElement.classList.add('no-scroll');
  }
}

export function unlockBodyScroll() {
  const { documentElement, body } = document;
  if (documentElement.classList.contains('no-scroll')) {
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    documentElement.classList.remove('no-scroll');
  }
}
document.addEventListener('DOMContentLoaded', () => {
  // Functions to open and close a modal
  function openModal($el) {
    $el.classList.add('is-active');
  }

  function closeModal($el) {
    $el.classList.remove('is-active');
  }

  function closeAllModals() {
    (document.querySelectorAll('.modal') || []).forEach(($modal) => {
      closeModal($modal);
    });
  }

  // Add a click event on buttons to open a specific modal
  (document.querySelectorAll('.js-modal-trigger') || []).forEach(($trigger) => {
    const modal = $trigger.dataset.target;
    const $target = document.getElementById(modal);

    $trigger.addEventListener('click', () => {
      openModal($target);
    });
  });

  // Add a click event on various child elements to close the parent modal
  (document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button') || []).forEach(($close) => {
    const $target = $close.closest('.modal');

    $close.addEventListener('click', () => {
      closeModal($target);
    });
  });

  // Add a keyboard event to close all modals
  document.addEventListener('keydown', (event) => {
    if (event.code === 'Escape') {
      closeAllModals();
    }
  });
});

// Array of field IDs
var fieldIds = ['field_2_43', 'field_2_30', 'field_2_32', 'field_2_4', 'field_2_5', 'field_2_6', 'field_2_7', 'field_2_8', 'field_2_9', 'field_2_10', 'field_2_11', 'field_2_12', 'field_2_13', 'field_2_14']; // Add more field IDs as needed


window.addEventListener('load', function () {
  // Get all elements with class "gfield-choice-input"
  var choiceInputs = document.querySelectorAll('.gfield-choice-input');

  // Loop through each element
  choiceInputs.forEach(function (input) {
    // Check if the "checked" attribute is present
    if (input.hasAttribute('checked')) {
      // Add the "checked" class to the parent element
      input.parentElement.classList.add('checked');
    }
  });
});

// Function to handle click events
function handleFieldClick(event) {
  // Find the nested input element within the clicked "gchoice" element
  var inputElement = event.currentTarget.querySelector('.switcher-style .gfield-choice-input');

  // Toggle the "checked" class on the clicked element
  event.currentTarget.classList.toggle('checked');

  // Toggle the checked state of the input element
  inputElement.checked = !inputElement.checked;

  // Determine the visibility and display based on the input element's checked state
  var visibility = inputElement.checked ? 'visible' : 'hidden';
  var display = inputElement.checked ? 'block' : 'none';

  // Loop through the field IDs and update their attributes and styles
  fieldIds.forEach(function (fieldId) {
    var field = document.getElementById(fieldId);
    if (field) {
      field.setAttribute('data-conditional-logic', visibility);
      field.style.display = display;
      var formFields = field.querySelectorAll('input, select, textarea');
      if (visibility === 'visible') {
        formFields.forEach(function (formField) {
          formField.removeAttribute('disabled');
        });
      } else {
        formFields.forEach(function (formField) {
          formField.setAttribute('disabled', 'disabled');
        });
      }
    }
  });
}

// Get all elements with the class "switcher-style" and "gchoice"
var gchoiceElements = document.querySelectorAll('.switcher-style .gchoice');

// Add a click event listener to each "gchoice" element
gchoiceElements.forEach(function (element) {
  element.addEventListener('click', handleFieldClick);
});


document.addEventListener('DOMContentLoaded', function () {
  const menuItemsWithSubmenu = document.querySelectorAll('.menu-item-has-children');

  // Function to check if any submenu is open and toggle the class
  function toggleDropdownOpenClass() {
    menuItemsWithSubmenu.forEach(function (menuItem) {
      const submenu = menuItem.querySelector('.sub-menu');
      if (submenu.style.display === 'block') {
        menuItem.classList.add('has-dropdown--open');
      } else {
        menuItem.classList.remove('has-dropdown--open');
      }
    });
  }

  menuItemsWithSubmenu.forEach(function (menuItem) {
    const submenu = menuItem.querySelector('.sub-menu');
    const menuItemLink = menuItem.querySelector('a');

    // Check if the screen width is less than 850px
    if (window.innerWidth < 850) {
      menuItemLink.addEventListener('click', function (e) {
        e.preventDefault(); // Prevent the default link behavior

        // Toggle the display of the submenu
        if (submenu.style.display === 'block') {
          submenu.style.display = 'none';
        } else {
          submenu.style.display = 'block';
        }

        // Toggle the class on the parent <li> when the submenu is opened or closed
        toggleDropdownOpenClass();
      });

      // Ensure the initial class state matches the submenu display state
      toggleDropdownOpenClass();
    }
  });
});

